export default {
  stringTooShort: ({label, value}) => `${label} is too short`,
  notInSchema: ({label, value}) => `${label} is not in the schema`,
  required: ({label, value}) => `${label} is required`,
  unknownFieldType: ({label, value}) => `${label} is an unkown field type`,
  notAnArray: ({label, value}) => `${label} is not an array`,
  notAnObject: ({label, value}) => `${label} is not an object`,
  notAString: ({label, value}) => `${label} is not a string`,
  notADate: ({label, value}) => `${label} is not a date`,
  notAnInteger: ({label, value}) => `${label} is not an integer`,
  notANumber: ({label, value}) => `${label} is not a number`,
  notAnId: ({label, value}) => `${label} is not a valid ID`,
  stringTooLong: ({label, value}) => `${label} is too long`,
  numberTooSmall: ({label, value}) => `${label} is too small`,
  numberTooBig: ({label, value}) => `${label} is too big`,
  notABoolean: ({label, value}) => `${label} is not a boolean`,
  notAnEmail: ({label, value}) => `${label} is not an email`,
  notUnique: ({label, value}) => `${label} is not unique`,
  notFound: ({label, value}) => `${label} not found`,
  invalid: ({label, value}) => `${label} is not a valid value`
}
